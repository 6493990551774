.game-description-style_wrapper_6603 { 
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    height: 100%;
  
  }

 } 
.game-description-style_description-wrapper-minimized_3993 { 
  margin-top: 12px;

  font-size: 1.2rem;
  line-height: 1.6rem;

  
  @media (max-width: 1024px) {
    
    margin-top: 14px;
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    flex: 1;
    padding-right: 8px;
    margin-top: 12px;
    font-size: 1.1rem;
    line-height: 1.3rem;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    font-size: 1rem;
    line-height: 1rem;
  
  }

 } 
.game-description-style_expand-wrapper_6776 { 
  display: inline-block
 } 
.game-description-style_description_9742 { 
  font-size: 1.2rem;
  line-height: 1.6rem;

  
  @media (max-width: 1300px) {
    
    font-size: 1rem;
    line-height: 1.25rem;
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    font-size: 1rem;
    line-height: 1.25rem;
  
  }

 } 