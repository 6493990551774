@keyframes menu-style_flashing-animation_0695 { 
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
 }
.menu-style_wrapper_0522 { 
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;

  & > :not(:last-child) {
    margin-right: 4px;
    
  @media (max-width: 824px) and (orientation: landscape) {
    
      margin-right: 2px;
    
  }

  }
 } 
.menu-style_item_7290 { 
  padding: 14px;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
  flex: 1;
  font-size: 1.2rem;
  transition: font-weight 150ms ease-out;
  display: inline-block;
  text-align: center;

  &:before {
    display: block;
    content: var(--v_uhy9qxqi);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden
  }

  &.flashing {
    animation: menu-style_flashing-animation_0695 500ms ease-out;
  }

  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 8px;
    font-size: 1rem;
  
  }


  
  @media (hover: hover) {
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }


  outline: none;
 } 
.menu-style_cursor_1325 { 
  position: absolute;
  z-index: -1;
  border-radius: 25px;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition: transform 250ms ease-out;
 } 