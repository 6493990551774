.scroll-view-style_scroll-view-wrapper_4937 { 
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-top: 36px;
  padding-right: 1rem;
  display: flex;

  
  @media (max-width: 1024px) {
    
    margin-top: 14px;
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    flex: 1;
    padding-right: 8px;
    margin-top: 14px;
  
  }

 } 
.scroll-view-style_scroll-content_4358 { 
  overflow-y: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    display: var(--v_c11suvvg);
  }

  scrollbar-width: var(--v_o7yvsibk);
 } 
.scroll-view-style_scroll-bar-wrapper_3691 { 
  width: 16px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  overflow: hidden;
 } 
.scroll-view-style_scroll-bar-thumb_7042 { 
  background-color: white;
  width: 10px;
  min-height: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: inset 0 0 13px 12px rgba(255, 255, 255, 0.65);
  box-sizing: border-box;
  transform: translate3d(0, 4px, 0);
 } 