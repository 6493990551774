

  html, body {
    width: 100%;
    height: 100%;
    
  }

  body {
    background-color: black;
    color: white;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }

  svg path {
    transition: fill 250ms ease-out;
  }

  #root {
    display: flex;
    width: 100%;
    height: 100%;
  }

  * {
    -webkit-tap-highlight-color: transparent !important;

    &:not(input) {
      user-select: none;
    }
  }


  .freeze {
    display: none !important;

    & * {
      display: none !important;
      animation: none !important;
      transition: none !important;
      background-image: none !important;
      backdrop-filter: none !important;
      filter: none !important;
    }
  }

  .pauseMouseInput {
    pointer-events: none !important;
  }

  

  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-thumb {
    //background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: inset 0 0 13px 12px rgba(255, 255, 255, 0.65);
    border: solid 3px transparent;
  }

  //::-webkit-scrollbar-thumb:hover {
  //  background: rgba(255, 255, 255, 0.1);
  //  background: rgba(0,0,0,0.4);
  //}

  ::-webkit-scrollbar-track {
    border: solid 3px transparent;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

