.progress-bar-style_wrapper-base_3040 { 
  width: 100%;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 0.25rem;
  box-sizing: border-box;
 } 
.progress-bar-style_wrapper-tv_3805 { 
  height: 32px;
  background-color: rgba(0, 0, 0, 0.5);
 } 
.progress-bar-style_progress_3420 { 
  height: 100%;
  border-radius: 1rem;
  background-color: #AFB7B9; // TODO: fix this, not documented
  transition: width 0.5s linear;
 } 