.lang-selection-style_lang-selection-wrapper-base_2719 { 
  display: flex;
  flex-direction: row;
  overflow: hidden;

  & > :not(:last-child) {
    margin-right: 10px;
  }
 } 
.lang-selection-style_lang-selection-wrapper-tv_6311 { 
  & > :not(:last-child) {
    margin-right: 20px;
  }
 } 