.content-rating-selector-style_wrapper_1807 { 
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  height: 26px;
  padding-top: 4px;

  
 } 
.content-rating-selector-style_item_0003 { 
  position: relative;
  font-size: 1rem;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  box-sizing: border-box;
  border: 2px solid transparent;

  &:focus, &:hover {
    border-color: var(--v_uicxor0d);
  }

  width: 26px;
  height: 26px;
  
 } 
.content-rating-selector-style_text_0725 { 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 } 
.content-rating-selector-style_cursor_1490 { 
  width: 26px;
  height: 26px;
  
  position: absolute;
  background-color: var(--v_uigfmdf9);
  border-radius: 50%;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-out;
 } 