@keyframes categories-style_animation_7428 { 
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  40% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
 }
.categories-style_category-title_5108 { 
  margin-top: 180px;
  width: 1280px;
  max-width: 98vw;
  margin-right: auto;
  margin-left: auto;
  align-self: flex-start;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  opacity: 0;

  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-name: categories-style_animation_7428;
  animation-duration: 450ms;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 100px;
    font-size: 1.5rem;
    margin-left: 0.5rem;
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 80px;
    font-size: 1.3rem;
  
  }


  
 } 