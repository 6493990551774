.avatar-selection-style_wrapper_7869 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  user-select: none;
  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 16px;
  
  }

 } 
.avatar-selection-style_text_3950 { 
  margin-top: 2px;
  margin-bottom: 22px;
  white-space: nowrap;
  color: var(--v_gm54mctt);
  user-select: none;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-bottom: 12px;
  
  }


  
 } 
.avatar-selection-style_buttons_1258 { 
  display: flex;
  flex-direction: row;

  & > :first-child {
    margin-right: 20px;
    
  }
 } 
.avatar-selection-style_avatars-grid_6936 { 
  width: 880px;
  height: 440px;

  

  
  @media (max-width: 1024px) {
    
    width: 424px;
    height: 212px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 320px;
    height: 160px;
  
  }


  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 0 14px 14px 14px;
 } 
.avatar-selection-style_avatar-item_1821 { 
  width: 196px;
  height: 196px;
  border-width: 6px;
  margin: 12px;

  &:focus {
    border-color: white;
  }

  

  
  @media (max-width: 1024px) {
    
    width: 94px;
    height: 94px;
    margin: 6px;
    border-width: 4px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 72px;
    height: 72px;
    margin: 4px;
    border-width: 2px;
    border-radius: 16px;
  
  }

 } 