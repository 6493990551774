.grid-style_wrapper_6701 { 
  display: flex;
  flex-direction: row;
  width: 1280px;
  max-width: 98vw;
  margin: 0 auto;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 100%;
  
  }


  
 } 