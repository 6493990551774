.eula-style_wrapper_5056 { 
  position: absolute;
  z-index: 901;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-color: black;
  background-size: cover;
 } 
.eula-style_box_2876 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 900px;
  max-width: calc(100vw - 72px);
  max-height: calc(100vh - 72px);
  padding: 28px 12px;

  & > :first-child {
    margin-bottom: 20px;
  }

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 18px 12px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  
  }


  
 } 
.eula-style_format-eula_9526 { 
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  white-space: pre-wrap;
 } 
.eula-style_text_9434 { 
  padding: 0 28px;
  overflow-y: auto;
  text-align: center;
  box-sizing: border-box;
  scroll-behavior: smooth;

  h1 {
    margin: 0 0 24px;
    font-size: 1.8rem;
  }

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 0 12px;

    h1 {
      font-size: 1.4rem;
    }
  
  }


  
 } 
.eula-style_buttons_5131 { 
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-top: 10px;

  & > :first-child {
    margin-right: 28px;
  }

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 0;
  
  }


  
 } 
.eula-style_spinner_7201 { 

  margin-bottom: 0 !important;
  width: 60px;
  height: 60px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 40px;
    height: 40px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 30px;
    height: 30px;
  
  }

 } 