.danger-button_danger-button_2216 { 
  background-color: var(--v_qd12u21d);

  &:focus, &:hover {
    background-color: var(--v_6yeuyb8f);
  }

  &:active {
    background-color: var(--v_a6lprp5x);
  }
 } 