.games-wrapper-style_games-wrapper_6397 { 

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 100vw;
  
  }


  //overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
 } 
.games-wrapper-style_leading-game_9890 { 
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 auto;
  height: 60vh;
  flex-shrink: 0;
  padding-bottom: 50px;
  width: 1280px;

  
  @media (max-width: 1300px) {
    
    width: 100%;
    height: 80vh;
    padding-bottom: 30px;
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 98vw;
    height: 80vh;
    padding-bottom: 20px;
  
  }


  
 } 
.games-wrapper-style_no-games-found_8752 { 
  margin-top: var(--v_bpa0m3fu);
  font-size: 2rem;
  text-align: center;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    font-size: 1.4rem;
  
  }

 } 