.lobby-style_wrapper_7065 { 
  display: flex;
  flex-direction: column;
  flex: 1;
 } 
.lobby-style_players-wrapper_8451 { 
  margin-top: 20px;
  max-height: 290px;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  flex: 1;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    border-radius: 30px;
  
  }

 } 