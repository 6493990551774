.logo_logo-base_5608 { 
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 160px;
  height: 135px;
  transition: 200ms;
  transition-property: width, height;
  flex-shrink: 0;
 } 
.logo_logo-responsive_8993 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 87px;
    height: 74px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 67px;
    height: 54px;
  
  }

 } 
.logo_logo-tv_7645 { 
  width: 206px;
  height: 174px;
 } 