.blur-menu-fallback-style_blur-menu-base-fallback_0291 { 
  border-radius: 35px;
  position: relative;
  overflow: hidden;
  z-index: 0;
 } 
.blur-menu-fallback-style_blur-menu-base-fallback-before_2042 { 
  z-index: -1;
  position: absolute;
  top: var(--v_9tl269z7);
  left: var(--v_ptruh7ar);
  right: var(--v_f35cmbsh);
  bottom: var(--v_h5pw428v);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  filter: var(--v_ovwjim1x);
 } 