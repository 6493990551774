@keyframes login-inputs-wrapper_animation_5701 { 
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
 }
.login-inputs-wrapper_login-inputs-wrapper-base_2476 { 

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 12px;
  min-height: 37px;
  
  & > :not(:last-child) {
    margin-right: 12px;
  }

  & > :not(:first-child) {
    flex-grow: 1;
  }

  & > * {
    animation: login-inputs-wrapper_animation_5701 0.6s linear;
  }
 } 
.login-inputs-wrapper_login-inputs-wrapper-responsive_0270 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 16px;
    margin-bottom: 10px;
  
  }

 } 
.login-inputs-wrapper_login-inputs-wrapper-tv_9204 { 
  margin-top: 56px;
  margin-bottom: 16px;

  & > :not(:last-child) {
    margin-right: 20px;
  }
 } 