.blur-button-style_button-wrapper_4011 { 
  padding: 10px 15px;
  color: var(--v_ko9hb0ti);
  text-align: center;
  transition: color 250ms ease-out, border 250ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  // prevent shift when bolding text on active/hover/focus | attr title must match text inside btn in order to prevent shift
  &:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  

 } 
.blur-button-style_focusable-wrapper_2046 { 

  &:focus, &:hover {
    outline: none;
    cursor: pointer;

    .blur-button-style_button-wrapper_4011 {
      border: 1px solid var(--v_q40l6jwe);
      color: var(--v_neoqbt7l);

      
    }
  }
 } 