.buttons_buttons_3840 { 
  display: flex;

  text-align: center;
  flex-direction: column;
  margin-top: 40px;

  width: 150px;

  & > :first-child {
    margin-bottom: 10px;
  }

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: auto;
    margin-top: 20px;
    flex-direction: row;

    & > :first-child {
      margin-bottom: 0;
      margin-right: 10px;
    }
  
  }


  
 } 