.profile-list-style_wrapper_7096 { 
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 20px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin: 0 10px;
  
  }


  
 } 