.remember-me-style_wrapper_5662 { 
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  outline: none;
  color: var(--v_pdoykczy);

  &:focus {
    color: var(--v_p9gm63d4);
    .checkbox-style_switch_6118 {
      
  box-shadow: 0 0 0 1px #66baf9;
  
;
    }
  }
 } 
.remember-me-style_text_3958 { 
  font-size: 0.75rem;
  padding-left: 8px;
 } 