.game-window-minimized-style_wrapper_6928 { 

  display: flex;
  overflow: hidden;
  max-width: calc((1280px * 80) / 100);
  justify-content: center;
  width: 98vw;
  
  
  @media (max-width: 1300px) {
    
    width: 85%;
    margin-right: 1vw;
  
  }


  --gameWindowHeight: 247px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    --gameWindowHeight: 170px;
    width: 100%;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    --gameWindowHeight: 150px;
  
  }


  

  height: var(--gameWindowHeight);
 } 
.game-window-minimized-style_cover_0371 { 
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: var(--gameWindowHeight);
  height: 100%;
 } 
.game-window-minimized-style_window_7479 { 
  width: 100%;
  max-width: 1280px;
  
  height: var(--gameWindowHeight);
 } 