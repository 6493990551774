.blur-box-fallback-style_blur-box-fallback_4758 { 
  position: relative;
  overflow: hidden;
  z-index: 0;
  backdrop-filter: none;
  border-radius: 40px;
  background-color: transparent;
 } 
.blur-box-fallback-style_blur-box-fallback-before_7428 { 
  z-index: -1;
  position: absolute;
  top: var(--v_fs13gjiw);
  left: var(--v_3l0q85r1);
  right: var(--v_i21y5j7i);
  bottom: var(--v_vd6tn4kx);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  filter: var(--v_okid3h8m);
 } 