.buttons_buttons_0299 { 
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: var(--v_dwwng5l9);
  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 100%;
  
  }

  
  & > * {
    flex: 1;
    text-align: center;
    max-width: 100%;
  }

  & > :not(:last-child) {
    margin-right: 20px;
    
  @media (max-width: 824px) and (orientation: landscape) {
    
      margin-right: 16px;
    
  }

  }
 } 