.message-box-style_wrapper_1332 { 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;;
 } 
.message-box-style_blurred-wallpaper-base_2777 { 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: var(--v_bxk7ld0m);
 } 
.message-box-style_blurred-wallpaper-tv_1717 { 
  background-repeat: no-repeat;
  background-color: black;
  background-size: cover;
 } 
.message-box-style_message-box-base_2926 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  padding: 1.25rem;
  font-size: 1.3rem;
  backdrop-filter: var(--v_yi6j5di1);

  text-align: center;
  max-width: 60vw;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    max-width: 75vw;
  
  }

 } 
.message-box-style_message-box-tv_2182 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  padding: 1.25rem;
  font-size: 1.3rem;
 } 
.message-box-style_message-wrapper_4217 { 
  display: flex;
  flex-direction: row;
  margin-bottom: 0.938rem;
 } 
.message-box-style_text_6330 { 
  align-self: flex-end;
  padding-bottom: 0.25rem;
  margin-left: 0.75rem;
 } 
.message-box-style_icon_8584 { 
  width: 2.5rem;
  height: 2.5rem;

  svg {
    fill: white
  }
 } 
.message-box-style_multiple-messages-wrapper_0957 { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.938rem;

  .message-box-style_icon_8584 {
    margin-bottom: 0.938rem;
  }

  .message-box-style_text_6330 {
    align-self: auto;
  }
 } 