.logo-medium_logo-medium-base_2392 { 
  width: 188px;
  height: 82px;
 } 
.logo-medium_logo-medium-responsive_4104 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 188px;
    height: 66px;
  
  }

 } 
.logo-medium_logo-medium-tv_2840 { 
  width: 324px;
  height: 142px;
 } 