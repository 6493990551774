.tags-style_tag_0158 { 
  border-radius: 50px;
  color: white;
  position: relative;
  font-weight: bold;
  background-color: var(--v_7bnne8zd);

  padding: 2px 15px 3px 15px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding-left: 10px;
    padding-right: 10px;
  
  }


  

 } 
.tags-style_tags-wrapper_8876 { 
  display: flex;
  width: 100%;

  .tags-style_tag_0158:nth-child(1) {
    z-index: 2
  }

  .tags-style_tag_0158:nth-child(2) {
    padding-left: 25px;

    margin-left: -18px;

    
  @media (max-width: 824px) and (orientation: landscape) {
    
      margin-left: -20px;
    
  }


    
  }
 } 