.player-style_wrapper_2614 { 
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 } 
.player-style_player-icon_7646 { 
  position: relative;
  max-width: 120px;
  max-height: 175px;
  width: 10vw;
  height: 10vw;

  
  @media (max-width: 1024px) {
    
    width: 8vw;
    height: 8vw;
  
  }

  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 18vw;
    height: 18vw;
  
  }

  
  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 14vw;
    height: 14vw;
  
  }


  

  opacity: var(--v_f0gedd52);
  fill: rgba(255, 255, 255, 0.9);
  user-select: none;
 } 
.player-style_player-index_3748 { 
  position: absolute;
  bottom: 0;
  font-size: calc(min(4rem, 4vw));

  
  @media (max-width: 1024px) {
    
    font-size: calc(min(4rem, 3.5vw));
  
  }


  
  @media (max-width: 824px) and (orientation: landscape) {
    
    font-size: calc(min(4rem, 6vw));
  
  }

  
  

  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  color: var(--v_al1ldm5k);
  opacity: 0.4;
 } 
.player-style_name-wrapper_2935 { 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 6px;
  
  }

 } 
.player-style_name_7093 { 
  font-size: 1.2rem;
  
  @media (max-width: 670px) and (orientation: landscape) {
    
    font-size: 1rem;
  
  }

  margin: 6px 6px;
 } 
.player-style_master-crown_6683 { 
  width: 24px;
  height: 24px;
  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 16px;
    height: 16px;
  
  }

 } 