.game-copyright-style_scroll-wrapper_6078 { 
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  -webkit-flex: 1 1 auto;
  overflow: auto;
  min-height: 0px;
  height: 100%;
 } 
.game-copyright-style_copyright_2781 { 
  font-size: 1.2rem;
  flex: 1;
 } 
.game-copyright-style_content-rating-wrapper_8057 { 
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  color: var(--v_hnc0quxd)
 } 
.game-copyright-style_content-rating-icon_3002 { 
  flex-shrink: 0;
  width: 63px;
  height: 82px;
  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 80px;
    height: 92px;
  
  }

 } 
.game-copyright-style_warning-wrapper_9628 { 
  margin-left: 8px;
  
  display: flex;
  flex-direction: column;
 } 
.game-copyright-style_warning-header_6465 { 
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
 } 
.game-copyright-style_warning-header-text_0370 { 
  font-size: 1rem;
  margin: 0;
  font-weight: normal;
 } 
.game-copyright-style_warning-header-icon_4451 { 
  width: 24px;
  margin-right: 10px;

  svg {
    fill: var(--v_na7sx8pw);
  }
 } 
.game-copyright-style_warning-message_5656 { 
  font-size: 1rem;
  margin: 0;
 } 