.global-navigation-style_wrapper_0520 { 
  position: absolute;
  z-index: 900;
  top: 4px;
  display: var(--v_px1gx940);
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  user-select: none;
 } 
.global-navigation-style_content_1916 { 
  width: 98vw;
  max-width: 1500px;
  
  

  display: flex;
  align-items: center;
  justify-content: space-between;
 } 
.global-navigation-style_sub-content_9794 { 
  width: 80vw;
  max-width: 1280px;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    max-width: 90vw;
    min-width: 90vw;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    max-width: 98vw;
    width: auto;
  
  }


  display: flex;
  align-items: center;
  justify-content: center;
 } 
.global-navigation-style_menu-wrapper_3702 { 
  flex: 1;
  max-width: 1050px;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    max-width: 500px;
  
  }

  
  @media (max-width: 670px) and (orientation: landscape) {
    
    max-width: 420px;
  
  }

 } 
.global-navigation-style_logo-wrapper_8065 { 
  width: 200px;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    max-width: 110px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    max-width: 100px;
  
  }

 } 
.global-navigation-style_aside-nav-wrapper_5510 { 

  display: flex;
  justify-content: flex-end;
  position: relative;

  width: 200px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    max-width: 110px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    max-width: 100px;
  
  }

 } 