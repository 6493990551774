.view-style_left-pan_9557 { 
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: var(--gameWindowHeight);
  max-width: var(--gameWindowMaxHeight);;

  height: 100%;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 150px;
    //padding-bottom: 8px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 110px;
  
  }

 } 
@keyframes view-style_appear-animation-keyframes_4380 { 
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }
.view-style_right-pan_2015 { 
  display: flex;
  flex-direction: row;
  flex: 1;

  padding: 20px 20px 20px 38px;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 10px 10px 10px 24px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    padding-left: 18px;
  
  }


  animation: view-style_appear-animation-keyframes_4380 500ms linear;
  
 } 