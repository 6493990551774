.game-heading-style_name_2768 { 
  margin: 10px 0 0;
  padding: 0;
  text-transform: capitalize;

  font-size: 2.2rem;
  
  
  
  
  @media (max-width: 1024px) {
    
    font-size: 1.5rem;
  
  }

  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin: 0 0 0;
    font-size: 1.4rem;
  
  }

 } 
.game-heading-style_studio_1026 { 
  color: var(--v_s2dy66o3);
  font-weight: normal;
  text-transform: capitalize;
  margin: 0;
  font-size: 1.2rem;
  padding-left: 4px;

  
  @media (max-width: 1024px) {
    
    font-size: 1rem;
  
  }

  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding-left: 0;
  
  }

 } 