.lang-icon-style_lang-icon-base_1651 { 
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;
  box-sizing: border-box;

  &:focus {
    border: 2px solid var(--v_3tftm9sa);
  }
 } 
.lang-icon-style_lang-icon-tv_1842 { 

  width: 55px;
  height: 55px;

  &:focus {
    border-width: 4px;
  }
 } 