.recovery-step-style_title-wrapper_1118 { 
  margin-top: 12px;
  text-align: center;
  transition: opacity 250ms ease-out;

  &.disabled {
    opacity: 0.25;
  }
 } 
.recovery-step-style_radio-buttons_3704 { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 } 
.recovery-step-style_radio-buttons-inner_1513 { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 } 
.recovery-step-style_radio-button-wrapper_4531 { 
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    margin-bottom: 0.5rem;
  }

  input {
    display: block;
  }

  input {
    margin-right: 0.5rem;
  }

  label {
    cursor: pointer;
  }
 } 
.recovery-step-style_content-wrapper-base_7810 { 
  margin: 22px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
 } 
.recovery-step-style_input-password-confirm_2108 { 
  margin-top: 0.625rem;
 } 
.recovery-step-style_content-wrapper-tv_5851 { 
  margin: 40px 0;
  width: 100%;
  max-width: 350px;

  > input:first-of-type {
    margin-bottom: 12px;
  }
 } 
.recovery-step-style_inputs-wrapper_2253 { 
  max-width: 220px;
  
  margin: 0 auto;
 } 
.recovery-step-style_error-wrapper_2619 { 
  text-align: center;
  width: 100%;
  margin-bottom: 22px;
  color: var(--v_dhpzxca3);
 } 
.recovery-step-style_password-error-wrapper_2688 { 
  font-size: 0.75rem;
  margin: 1rem 0 0 0;
  color: var(--v_e2h9ez00)
 } 