.tcwrapper_tcwrapper-base_2054 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 900px;
  max-width: calc(100vw - 72px);
  max-height: calc(100vh - 72px);
  padding: 28px 12px;

  & > :first-child {
    margin-bottom: 24px;
  }
 } 
.tcwrapper_tcwrapper-responsive_6913 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 18px 12px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  
  }

 } 
.tcwrapper_tcwrapper-tv_5626 { 
  width: 1200px;
  padding: 32px 12px;

  & > :first-child {
    margin-bottom: 44px;
  }
 } 