.support-message-box-style_wrapper_4698 { 
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
 } 
.support-message-box-style_box-title-wrapper_2696 { 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
 } 
.support-message-box-style_box-title_5206 { 
  font-size: 1.5rem;
 } 
.support-message-box-style_icon_4643 { 
  width: 1.25rem;
  display: inline-block;
  margin-right: 4px;

  svg {
    fill: white;
  }
 } 
.support-message-box-style_message-box_7983 { 
  border-radius: 20px;
  max-width: 765px;
  margin: 0 auto;
  font-size: 1.25rem;
  padding: 24px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 14px;
    font-size: 1.125rem;
  
  }


  
 } 
.support-message-box-style_build-version_0209 { 
  line-height: 1.5rem;
  margin-bottom: 1.5rem;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-bottom: 0;
  
  }

 } 
.support-message-box-style_message_7688 { 
  margin-bottom: 24px;
  line-height: 1.5rem;
 } 
.support-message-box-style_support-link_1120 { 
  color: #FFDA9F;
  font-style: italic;
 } 
.support-message-box-style_close-wrapper_6156 { 
  text-align: center;

  > div {
    display: inline-block;
  }
 } 