.wrapper_wrapper_6224 { 

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  min-width: 360px;
  min-height: 345px;
  box-sizing: border-box;
  
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 26px;
    min-width: auto;
    min-height: 276px;
  
  }


  
  
  input {
    margin-bottom: 10px;
  }
 } 