.logo-nav_logo-nav_2147 { 
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  width: 136px;
  height: 40px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 81px;
    height: 24px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 79px;
    height: 23px;
  
  }


  
 } 