.welcome-message_welcome-message-base_3606 { 
  margin-top: 16px;
  margin-bottom: 32px;
  white-space: nowrap;
  color: var(--v_veissery);
 } 
.welcome-message_welcome-message-responsive_6421 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 9px;
    margin-bottom: 12px;
  
  }

 } 
.welcome-message_welcome-message-tv_0044 { 
  margin-top: 28px;
  margin-bottom: 54px;
 } 