.action-buttons-style_wrapper-base_2377 { 

  display: flex;

  
  
  & > * {
    margin-right: 8px;
    margin-top: 12px;
    padding: 0.7rem 1rem;
    flex: 1;
  }

  
  @media (max-width: 1024px) {
    
    & > * {
      padding: 0.7rem 0.5rem;
      min-width: 110px;
    }
  
  }


  
 } 
.wrapper_base_css_cond_9104 { 

    flex-direction: row;
    flex-wrap: wrap;
      //max-width: var(--v_birlevzk);  // TODO : change flex layout instead of hardCoding width values
    max-width: 400px;

    & > :nth-child(1) {
      order: 1;
    }

    & > :nth-child(2) {
      order: 3;
    }

    & > :nth-child(3) {
      order: 2;
    }

    & > :nth-child(4) {
      order: 4;
    }

    
  @media (max-width: 1024px) {
    
      max-width: unset;
    
  }


    
   }
.wrapper_base_css_cond_3047 { 
    flex-direction: row;
    align-self: flex-start;
   }
.action-buttons-style_wrapper-mobile_7217 { 

  display: flex;
  //justify-content: space-evenly;
  flex-direction: column;
  flex: 1;

  
  @media (max-width: 824px) and (orientation: landscape) {
    

    & > * {
      margin-top: 8px;
      margin-left: 8px;
      margin-right: 8px;
      min-width: unset;
      padding: 0.4rem 0;
    }
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    & > * {
      margin-top: 6px;
      margin-left: 4px;
      margin-right: 4px;
    }
  
  }
 // Removes React warning, hasQuickMatch prop is passed to dom node
    // if it is not used in the styled component
  
 } 
.wrapper_mobile_css_cond_8255 {  }