.nav-button_nav-button_2114 { 

  align-self: center;
  flex-shrink: 0;
  outline: none;
  cursor: pointer;
  width: 62px;
  height: 62px;
  
  margin-top: -16px;
  
  ;

  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 45px;
    height: 45px;
  
  }
;

  svg {
    fill: var(--v_ockfdd81);
  }

  &:focus, &:hover {
    svg {
      fill: var(--v_pgg1eb5k);
    }
  }

  &:active {
    svg {
      fill: var(--v_659dowol);
    }
  }
 } 