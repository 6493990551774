.heading_heading_7400 { 
  
  font-size: 1rem;
  
  font-weight: normal;
  margin-bottom: 26px;
  
  color: var(--v_3zd4elle);

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-bottom: 22px;
  
  }


  
 } 