.primary-button_primary-button_3004 { 
  background-color: var(--v_d7hvsgyg);
  color: var(--v_b20nxl2o);
  font-weight: bold;
  
  &:focus,
  &:hover {
    background-color: var(--v_bpdf20gs);
    color: var(--v_e1ug9qr7);
  }

  &:active {
    background-color: var(--v_lhmmsgrm);
    color: var(--v_a1i1i0ap);
  }
 } 