.profile-manager-style_wrapper_2865 { 
  display: flex;
  flex-direction: row;
  align-items: center;
 } 
.profile-manager-style_text_9644 { 
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0 0 0 10px;
  
 } 
.profile-manager-style_icon_9982 { 
  width:30px;
  svg {
    fill: white;
  }
 } 