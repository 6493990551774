.profile-lock-style_wrapper_0006 { 
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 16px;
  padding: 12px;
  flex-grow: 1;

  justify-content: space-between;
  
 } 
.profile-lock-style_row_0287 { 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > :first-child {
    margin-right: 8px;

    
  }
 } 
.profile-lock-style_text_9106 { 
  font-size: 1rem;
 } 
.profile-lock-style_lock-checkbox_2634 { 
  outline: none;
  
  &:focus {
    .checkbox-style_switch_6118 {
      
  box-shadow: 0 0 0 1px #66baf9;
  
;
    }
  }
 } 
.profile-lock-style_pin-code-wrapper_3799 { 
  position: relative;
  width: 148px;
  height: 35px;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 135px;
    height: 35px;
  
  }

 } 