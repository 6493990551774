.profile-style_wrapper_0232 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 0;
  outline: none;
  flex: 1;
  flex-shrink: 0;
  user-select: none;

  margin: 0 20px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin: 0 0;
  
  }
;

  
 } 
.profile-style_profile-avatar_7578 { 

  width: 144px;
  height: 144px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin: var(--v_ow07kxll);
    height: 120px;
    width: 120px;
  
  }
;

  
  @media (max-width: 670px) and (orientation: landscape) {
    
    margin: var(--v_8ehga1n4);
    width: 90px;
    height: 90px;
  
  }
;

  
  &:focus {
    border-color: var(--v_ailpz9nj);
  }

  
  @media (hover: hover) {
    
    &:hover {
      border-color: var(--v_qxd2fyk5);
    }
  }

  &:active {
    border-color: var(--v_fgb4pp1v);
  }
 } 
.profile-style_tag_9721 { 
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 4px 14px;
  margin-top: 8px;
  text-align: center;
  max-width: 110%;

  
  @media (max-width: 670px) and (orientation: landscape) {
    
    padding: 2px 6px;
  
  }
;

  
 } 
.profile-style_text_8200 { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
 } 
.profile-style_icon_5788 { 
  margin-left: 6px;
  width: 15px;
  
 } 