@keyframes game-item-style_animation_1025 { 
  0% {
    opacity: 1;
    //transform: translateY(100vh);
    transform: translate3d(0, 100vh, 0) scale3d(1, 1, 1);
  }
  40% {
    //transform: translateY(-10vh);
    transform: translate3d(0, -10vh, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 1;
    //transform: translateY(0);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    animation: none;
  }
 }
@keyframes game-item-style_animation-mobile_5273 { 
  0% {
    opacity: 1;
    //transform: translateY(100vh);
    transform: translate3d(0, 100vh, 0) scale3d(1, 1, 1);
  }
  40% {
    //transform: translateY(-10vh);
    transform: translate3d(0, -5vh, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 1;
    //transform: translateY(0);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    animation: none;
  }
 }
.game-item-style_game-item_4246 { 
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  width: 100%;
  padding-top: 100%;
  
 } 
.game-item-style_game-item-text-fallback_1581 { 
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  justify-content: center;
 } 
.game-item-style_game-grid-item_1049 { 

  width: 100%;
  outline: none;
  cursor: pointer;
  padding-top: var(--v_aowema06);
  transition: box-shadow 150ms ease-out;

  &:focus {
    box-shadow: inset 0 0 0 4px white;
    
  }

  
  @media (hover: hover) {
    
    &:hover {
      box-shadow: inset 0 0 0 4px white;
    }
  
  }


  opacity: 0;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-name: game-item-style_animation_1025;
  animation-duration: 1000ms;
  backface-visibility: hidden;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    animation-name: game-item-style_animation-mobile_5273;
  
  }


  

 } 
.game_grid_item_css_cond_7085 { 
    animation: none;
    opacity: 1;
   }
.game-item-style_tags-wrapper_6561 { 
  position: absolute;

  bottom: 16px;
  right: 16px;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    bottom: 12px;
    right: 12px;
  
  }

 } 
@keyframes game-item-style_fav-change_0303 { 
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.3);
  }
  45% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
 }
.game-item-style_favorite-grid-item_9599 { 
  position: absolute;
  cursor: pointer;

  user-select: none;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 12px;

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 24px;
    height: 24px;
  
  }

  
  svg {
    fill: var(--v_a6qerc8x);
    transition: fill 600ms ease-out;

    path {
      stroke-width: 2;
      stroke: var(--v_5jzz62sv);
      transition: stroke 600ms ease-out;
    }
  }

  &.animate {
    animation: game-item-style_fav-change_0303 600ms ease-out;
  }
 } 
.game-item-style_favorite-icon_6373 { 
  outline: none;

  &:focus, &:hover {
    transform: scale(1.2);
  }
 } 