.checkbox-style_switch_6118 { 
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  
 } 
.checkbox-style_checkbox_2652 { 
  opacity: 0;
  width: 0;
  height: 0;
 } 
.checkbox-style_wrapper_0045 { 
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  outline: none;

  &:focus > :nth-child(2n) {
    
  box-shadow: 0 0 0 1px #66baf9;
  

  }

  
  
  .checkbox-style_checkbox_2652:checked + .checkbox-style_switch_6118 {
    background-color: var(--v_rsppr2ww);
  }

  .checkbox-style_checkbox_2652:checked + .checkbox-style_switch_6118:before {
    
    transform: translateX(14px);

    
  }
  
 } 