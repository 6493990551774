.charts_titles-wrapper_9095 { 
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 130px;
  flex-shrink: 0;
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    margin-top: 60px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    margin-top: 60px;
  
  }

 } 