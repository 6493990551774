.profile-name-style_tag_8654 { 
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 7px 14px;
  text-align: center;

  
 } 
.profile-name-style_text_7701 { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
 } 
.profile-name-style_icon_1370 { 
  margin-left: 6px;
  width: 15px;
  height: 20px;
  
 } 