.indication_indication_2866 { 
  margin-top: 0.6rem;
  font-size: 0.75rem;
  user-select: none;
  outline: none;
  align-self: center;
  color: var(--v_ko37cw91);

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    font-size: 1.05rem;
  
  }

 } 