.pin-code-wrapper_pin-code-wrapper_8760 { 
  width: 146px;
  height: 36px;

  

  
  @media (max-width: 824px) and (orientation: landscape) {
    
    width: 172px;
    height: 46px;
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    width: 172px;
    height: 34px;
  
  }

 } 