.wrapper_wrapper-base_1471 { 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 48px;
  box-sizing: border-box;
 } 
.wrapper_wrapper-responsive_7769 { 
  
  @media (max-width: 824px) and (orientation: landscape) {
    
    padding: 16px 24px;
    min-width: var(--v_jgovbcj3);
    max-width: var(--v_p8zbp4m1);
  
  }


  
  @media (max-width: 670px) and (orientation: landscape) {
    
    min-width: var(--v_vmcn06dx);
    padding: 16px 18px;
  
  }
;
 } 
.wrapper_wrapper-tv_0753 { 
  padding: 28px 90px;
 } 